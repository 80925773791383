.root [class~='ant-select-selection-item'] span {
  display: inline !important;
  color: var(--gray-13) !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.status {
  width: 100%;
  text-align: center;
}
