.root {
  & fieldset {
    border-color: transparent !important;
  }
}

.label {
  top: 4px !important;
  left: 12px !important;
  font-size: 10px !important;
  line-height: 12px !important;
  transform: none !important;

  &[class*='Mui-focused'] {
    color: #606176 !important;
  }
}

.select {
  border-radius: 8px !important;
  border: 1px solid #ECF0F5 !important;

  & [class*='MuiSelect-select'] {
    height: 20px !important;
    min-height: 20px !important;
    padding-top: 15px !important;
    padding-bottom: 3px !important;
    padding-left: 12px !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #29292C !important;
    background-color: white !important;
    border-radius: 8px !important;
    border: none !important;
  }

  &[class*='Mui-focused'] {
    border-color: #A7AFB7 !important;
  }
}

.selectAll {
  & [class*='MuiSelect-select'] {
    color: #838998 !important;
  }
}

.option {
  margin-left: 8px !important;
  margin-right: 8px !important;
  margin-bottom: 2px !important;
  padding: 9px 8px !important;
  font-size: 16px !important;
  line-height: 18px !important;
  border-radius: 4px !important;

  &:first-child {
    margin-top: 2px !important;
  }

  &:hover {
    background-color: #f6f7f9 !important;
  }

  &[class*='Mui-selected'] {
    background-color: #ECEEF2 !important;
  }
}

.optionAll{
  margin-bottom: 8px !important;
  overflow: visible !important;

  &::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #f2f2f2;
  }
}

[class*='MuiMenu-root'] [class*='MuiPopover-paper'] {
  margin-top: 6px;
  border-radius: 8px !important;
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07) !important;
  transition-duration: 0s !important;
}
