.accordionContent {
  position: relative;
  display: grid;
  align-content: start;
  gap: 32px;
  max-width: 890px;
}

.divider {
  border-bottom: 1px solid #ECF0F5;
}
