.selectIconStyle {
  display: inline-flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  right: 10px;
  cursor: pointer;
  flex: 0 1 auto;
}

.selectWrap {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    height: 4px;
    width: 100%;
  }
}

.menuSelectStyle {
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  margin-top: 4px;
  background-color: #ffff;
  border-radius: 8px;
  padding: 10px 4px;
  width: max-content;
  min-width: 100%;
  position: absolute;
  z-index: 99999;
  box-sizing: border-box;
  max-height: 420px;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid #ECEEF2;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #CED0D7;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c6c8ce;
  }
}

.menuSelectItemStyle {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  cursor: pointer;
  color: #838998;
  font-size: 16px;
  border-radius: 4px;
  &:hover {
    background: #FBFBFB;
  }
}
