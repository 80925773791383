.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.root > div:first-child {
  flex-grow: 1;
}
