.title {
  margin-bottom: 30px;

  color: #606176;
  font-size: 24px;
  line-height: 29px;
}

.wrapper {
  margin-bottom: 24px;

  background-color: #ECF0F5;
  border-radius: 8px;
}

.table {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 14px;
  line-height: 17px;
  padding: 0px 18px;

  &--header {
    height: 40px;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
    font-weight: 500;
    color: #838998;
  }

  &--body {
    height: 56px;
    color: #29292C;
  }
}

.row {
  display: flex;
  align-items: center;
}

.logo {
  width: 20px;
  margin-right: 6px;
}

.error {
  color: #EC3E72;
}

.arrow {
  margin-left: 4px;
}

.createTransaction {
  height: 60px;
  width: 173px;
  margin-right: 40px;
}

.cancel {
  height: 60px;
  width: 89px;
}
