.filters {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.block {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.blockSkeleton {
  align-items: center;
  gap: 8px;
}

.icon {
  display: block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.yes,
.yes [class~='ant-select-item-option-content'] {
  color: var(--green-6) !important;
}

.no,
.no [class~='ant-select-item-option-content'] {
  color: var(--red-6) !important;
}

.table {
  flex-grow: 1;
}

.statusTable {
  width: 88px;
}

.edit {
  opacity: 0;
}

.row:hover .edit {
  opacity: 1;
}
