.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #eceef2;
  border-radius: 4px;
}

.text {
  padding: 2px;
  color: #fff;
  font-size: 10px;
  line-height: 12px;
  background-color: #7c55eb;
  border-radius: 2px;
}
