.switcherWrap {
  display: flex;
  font-size: 20px;
  align-items: center;
  color: #29292c;
  cursor: pointer;
}

.switcherImg {
  margin-right: 15px;
  width: 24px;
  height: 24px;
}
