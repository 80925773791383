.backContract {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

  &:hover {
    opacity: 0.8;
  }
}

.deleteContract {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.popUpContractButtons {
  margin-top: 40px;
}

.buttonDateRoot {
  &:hover {
    background-color: inherit;
  }
}

.labelShrink {
  color: #838998 !important;
  font-size: 12px !important;
  line-height: 12px !important;
}

.labelRequered {
  color: #838998;
  font-size: 16px;
  line-height: 18px;
}

.textFieldRoot {
  width: 100%;
  height: 84px;
}

.inputFilled {
  border-radius: 8px;
  height: 60px;
  box-sizing: border-box;
  color: #29292C;
  font-size: 16px;
  line-height: 18px;
}

.customInputRoot {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ECF0F5;
  &:hover {
    background-color: #FAFBFE;
  }
}

.inputFocus {
  border: 1px solid #A7AFB7;
  background-color: #fff!important;
}

.helperError {
  color: #EC3E72 !important;
  margin-top: 4px;
  font-size: 12px;
  line-height: 1;
}

.labelError {
  color: #EC3E72 !important;
}

.inputError {
  border: 1px solid #EC3E72 !important;
  border-radius: 8px;
}

.wrapperContract {
  overflow-y: auto;
  box-sizing: border-box;
  width: 630px;
  max-height: 744px;
  background-color: #F7F8FA;
  border-radius: 8px;
  padding: 40px 40px 40px 40px;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 32px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #CECECE;
    border-radius: 8px;
  }
}

.titleContract {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 29px;
  color: #606176;
  margin-bottom: 40px;
  position: relative;
}

.dropZoneContainer {
  margin-top: 24px;
}

.dateField {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
