.blockWithTwoCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
}

.account_number_block {
  display: grid;
  gap: 5px;
}
