.wrapper {
  max-width: 890px;
  display: grid;
  gap: 32px;
}

.accordionButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.tableRowStyle {
  & > th {
    background-color: inherit;
    color: #838998;
    border: none;
    font-size: 14px;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  }
  & > td {
    background-color: inherit;
    color: #29292C;
    border: none;
    font-size: 14px;
  }
}


