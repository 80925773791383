.form [class~='ant-tabs-tabpane'] > [class~='ant-form-item']:last-child {
  margin-bottom: 0;
}

.tabs {
  margin-bottom: 64px !important;
}

.title {
  margin-bottom: 12px;
}

.actions {
  display: flex;
  gap: 24px;
}
