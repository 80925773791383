.wrapper {
  position: relative;
  max-width: 894px;
}
.tableRowStyle {
  background-color: #fff;
  &>td {
    border: none;
  }
  &:hover {
    cursor: pointer;
    background-color: #FBFBFD;
  }
  &:hover .actionTableCell {
    opacity: 1;
  }
}

.headRow {
  & th {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  }
}

.actionTableCell {
  opacity: 0;
  transition: opacity 0.4s;
}
