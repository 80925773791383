.button {
  width: max-content;
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 15px;
  text-transform: inherit;
  font-size: 16px;
  line-height: 1;
  border-radius: 8px;
  transition: border 0.4s, background-color 0.4s, color 0.4s;
  cursor: pointer;

  &:active {
    border: 1px solid #606176;
  }

  &:disabled {
    opacity: 0.5;
  }

  &--onlyLoadingIcon {
    min-width: 160px;
    justify-content: center;
  }
}

.button_fill {
  color: #ffffff;
  background-color: var(--bg-color);

  &:hover {
    background-color: var(--bg-color-hover);
  }

  &:focus {
    background-color: var(--bg-color-focus);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.button_outline {
  border: 1px solid var(--bg-color);
  color: var(--bg-color);

  &:hover {
    border: 1px solid var(--bg-color-hover);
    color: var(--bg-color-hover);
  }

  &:focus {
    border: 1px solid var(--bg-color-focus);
    color: var(--bg-color-focus);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
