.blockWithTwoCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
}

.title {
  overflow-wrap: anywhere;
  color: #838998;
  line-height: 24px;
}

.tableRow {
  display: grid;
  align-items: center;
  grid-template-columns: 180px 105px 147px 147px 147px 140px;
}

.accordionButton {
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  text-align: start;
  font-size: 20px;
  line-height: 24px;
  color: #29292C;
  gap: 8px;
  cursor: pointer;
}
