.wrapper {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  font-size: 24px;
  line-height: 29px;
  color: #606176;
}

.status {
  height: 25px;
  box-sizing: border-box;
  color: #ffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  line-height: 2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
}

.icon {
  align-self: center;
  cursor: pointer;
  width: 29px;
}
