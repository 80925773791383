.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  color: #838998;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.4s;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }

  &:hover .switchMark {
      opacity: 0.7;
  }
}

.switchMark {
  display: flex;
  align-items: center;
  width: 38px;
  height: 20px;
  padding: 0 2px;
  margin-right: 8px;
  border-radius: 20px;
  background-color: #CED0D7;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.4s, background-color 0.4s;

  &:before {
    content: '';
    position: relative;
    display: flex;
    height: var(--size-marker);
    width: var(--size-marker);
    border-radius: 50%;
    background-color: #FFFFFF;
    left: var(--left-position);
    transition: left 0.2s;


  }
}
