.wrapper {
  width: 889px;
}

.clientHeader {
  color: #838998 !important;
  font-size: 14px;
  line-height: 17px;
  background-color: #ffffff !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.tableRowStyle {
  background-color: #fff;

  & > td {
    border: none;
  }

  &:hover {
    cursor: pointer;
    background-color: #fbfbfd;
  }

  &:hover td {
    opacity: 1 !important;
  }
}

.tableRow {
  height: 56px;
  background-color: #fff;

  &:hover {
    background-color: #fbfbfd;

    & .iconButton {
      opacity: 1;
    }
  }

  &:hover .underline {
    text-decoration: underline;
  }
}

.tableRowPointer {
  cursor: pointer;
}

.cell {
  padding: 0 !important;
  border: none !important;
}

.iconCell {
  width: 24px;
}

.nameCell {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.noFile {
  color: #ec3e72;
}

.rightIconCell {
  padding-right: 20px !important;
  padding-left: 0 !important;
}

.leftIconCell {
  padding-left: 20px !important;
  padding-right: 0 !important;
}

.icon {
  display: block;
  width: 24px;
  height: 24px;
}

.iconButton {
  opacity: 0;
  cursor: pointer;
}

.uploadBlock {
  position: relative;
}

.uploadInput {
  opacity: 0;
  display: 0;
}

[class='MuiTooltip-popper'] {
  top: 10px !important;
}

[class='MuiTooltip-popper'] > div {
  font-size: 14px !important;
  line-height: 17px !important;
  background-color: rgba(41, 41, 44, 0.8) !important;
  border-radius: 4px !important;
}

.actionButtonWrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
}
