.wrapper {
  background-color: #FFFFFF;
  border: 1px solid #ECF0F5;
  border-radius: 8px;
  line-height: 19px;
  color: #606176;
  display: inline-flex;
  align-items: center;
  padding: 3px;
  width: fit-content;
  box-sizing: border-box;
  & > button {
    font-size: 16px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 5px;
    height: 100%;
    cursor: pointer;
  }
}
