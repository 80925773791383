.root {
  min-height: 100%;
  padding: 20px;
  margin: 0 auto;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.fix {
  max-width: 934px;
}

.form {
  max-width: 718px;
  padding-bottom: 64px;
}

.flexibleLimit {
  min-width: 934px;
  max-width: 1334px;
}
