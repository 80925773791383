.editWrap {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  justify-content: center;
}

.editMenu {
  z-index: 100;
  padding: 10px 18px;
  position: absolute;
  top: 45px;
  right: 0;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0 14px 40px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}
