.rightContent {
  display: flex;
  gap: 20px;
}

.table {
  flex-grow: 1;
  margin-bottom: 20px;
}

.gameBoost42 {
  color: var(--purple-6);
}

.row {
  cursor: pointer;
}

.deleted {
  background-color: var(--red-1);
}
