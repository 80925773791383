.root {
  margin-top: 24px;
  margin-bottom: 12px;
}

.title {
  margin-bottom: 38px;
  font-size: 24px;
  line-height: 30px;
  color: #606176;
}

.container {
  display: flex;
}

.company {
  min-width: 320px;
  margin-right: 20px;
}

.type {
  min-width: 256px;
  margin-right: 20px;
}

.payments {
  min-width: 200px;
}
