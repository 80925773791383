.wrapper {
  padding: 25px 45px;
}

.backButton {
  padding: 0;
  color: #606176;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.content {
  max-width: 600px;
  margin-top: 30px;
}

.actions {
  margin-top: 40px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 20px;
}

.button {
  height: 40px;
}
