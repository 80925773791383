.modalText {
  width: 555px;
  margin-bottom: 32px;
}

.modalActions {
  height: 60px;
  justify-content: flex-start;
  gap: 32px;
}
