.form {
  padding-right: 72px !important;
}

.row {
  position: relative;
}

.remove {
  position: absolute !important;
  top: 10px !important;
  right: -64px !important;
}

.total {
  padding: 16px;
  border-radius: 8px;
  background-color: var(--gray-4);
}

.totalTitle {
  color: var(--gray-10) !important;
  margin-right: 24px;
}

.totalError {
  background-color: var(--red-1);

  & .totalAmount {
    color: var(--red-6);
  }
}

.error {
  font-weight: 400 !important;
}
