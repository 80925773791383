.titleWrapper {
  display: flex;
  align-items: center;
}

.status {
  margin-left: 12px;
}

.headerWrapper {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 890px;
}

.actionWrapper {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 15px;
}

.footer {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
}
