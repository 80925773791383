.popup {
  width: 630px;
}

.actions {
  margin-top: 32px;
  gap: 40px;
  height: 60px;
  justify-content: flex-start;
}

.pdfIcon {
  flex-shrink: 0;
}

.fileWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;

  &:hover .name {
    border-color: #24242b;
  }
}

.name {
  padding: 5px;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid transparent;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: none;
  cursor: pointer;
}

.closeIcon {
  width: 20px;
  height: 20px;
}
