.selectWrap {
  position: relative;
  box-sizing: border-box;
}

.inputStyle {
  background: #FFFFFF;
  border: 1px solid #ECF0F5;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #29292C;

  &::selection {
    background: #CFDAE6;
  }

  &:hover {
    background: #FAFBFE;
    border: 1px solid #ECF0F5;
    cursor: text;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: #838998;
    ;
    font-size: 16px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #838998;
    font-size: 16px;
  }

  &::placeholder {
    color: #838998;
    font-size: 16px;
  }

  &:focus {
    border: 1px solid #A7AFB7;
  }
}

.selectIconStyle {
  height: 100%;
  display: grid;
  align-items: center;
  position: absolute;
  cursor: pointer;
}

.labelStyle {
  position: absolute;
  color: #606176;
  line-height: 12px;
}

.helperStyle {
  color: rgb(236, 62, 114);
  position: absolute;
  font-size: 12px;
}

.menuSelectStyle {
  box-shadow: 0px 14px 40px rgba(0, 0, 0, 0.07);
  margin-top: 6px;
  background-color: #ffff;
  border-radius: 8px;
  padding: 10px 8px;
  width: max-content;
  min-width: 100%;
  position: absolute;
  z-index: 99999;
  box-sizing: border-box;
  max-height: 420px;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid #ECEEF2;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #CED0D7;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c6c8ce;
  }
}

.menuSelectItemStyle {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  cursor: pointer;
  color: #606176;
  font-size: 16px;
  border-radius: 4px;

  &:hover {
    background: rgba(236, 238, 242, 0.3);
  }
}
.points {
  position: absolute;
  line-height: 1;
}
.itemAllStyle {
  border-bottom: 1px solid rgba(229, 229, 229, 0.5);
  padding-bottom: 5px
}
