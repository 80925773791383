.wrapper {
  max-width: 894px;
}

.filter {
  display: flex;
  gap: 24px;
  margin-bottom: 12px;
}

.tableRowStyle {
  background-color: #fff;
  cursor: pointer;

  & > td {
    border: none;
  }

  &:hover {
    background-color: #fbfbfd;
  }

  .tableIconBtn {
    display: none;
    cursor: pointer;
  }

  &:hover .tableIconBtn {
    display: inline;
  }
}

.tableHeadCell {
  background-color: #fff !important;
  height: 27px;
}

.tableCellSmall {
  width: 60px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center !important;
}

.link {
  text-decoration: underline;
}
