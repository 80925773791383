.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .center {
    .title {
      color: var(--grey-gray-13-total-black, #090909);
      font-family: Muller;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    .calendarWrapper {
      margin: 10px 0 0 0;
      width: 290px;
    }

    .tablesWrapper {
      width: 934px;
      margin: 10px 0 0 0;
      display: flex;
      flex-direction: row;
      gap: 32px;
      align-items: flex-end;

      @media screen and (max-width: 1199px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .firstRow {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .row {
        color: var(--grey-gray-13-total-black, #090909);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        height: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .table1,
      .table2,
      .table3 {
        width: 290px;
      }

      .table1 [class~='ant-table-cell'],
      .table2 [class~='ant-table-cell'],
      .table3 [class~='ant-table-cell'] {
        padding: 8px 6px 8px 16px !important;
      }
    }
  }
}
