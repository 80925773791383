.editWrap {
  cursor: pointer;
  border: 1px solid #A7AFB7;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editMenu {
  z-index: 100;
  padding: 0 0 8px 6px;
  position: absolute;
  top: 44px;
  right: 0;
  width: 205px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0 14px 40px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 36px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  &:hover {
    opacity: 0.8;
  }
  & > img {
    margin-right: 17px;
  }
}
