.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 40px;

  .center {
    .header {
      width: 934px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .title {
        color: #090909;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
      }

      .addItem {
        // width: 133px;
        height: 40px;
        background: #9197ad;
        border: none;
        color: #fff !important;
        background-color: #9197ad !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        &:hover {
          background: #686f88;
          background-color: #686f88 !important;
        }
      }
    }

    .tablesWrapper {
      width: 934px;
      margin: 10px 0 0 0;
      display: flex;
      flex-direction: row;
      gap: 32px;
      align-items: flex-end;

      .firstRow {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .row {
        color: var(--grey-gray-13-total-black, #090909);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        height: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .table1,
      .table2,
      .table3 {
        width: 290px;
      }

      .table1 [class~='ant-table-cell'],
      .table2 [class~='ant-table-cell'],
      .table3 [class~='ant-table-cell'] {
        padding: 8px 6px 8px 16px !important;
      }
    }
  }

  .createForm {
    width: 718px;

    .title {
      margin: 20px 0 24px;
      color: #090909;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    .row {
      display: flex;
      gap: 32px;
      margin-bottom: 32px;
    }

    .footer {
      display: flex;
      gap: 24px;

      .save {
        border-radius: 8px;
        background: #9197ad;
        border: none;

        color: #fff !important;
        background-color: #9197ad !important;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        &:hover {
          background: #686f88;
          background-color: #686f88 !important;
        }
      }
    }
  }

  .cardForm {
    width: 934px;
    margin-top: 20px;

    .row {
      display: flex;
      gap: 32px;
      margin-bottom: 32px;

      .item {
        width: 100%;
        display: flex;
        flex-direction: column;

        .itemRow {
          display: flex;
          height: 56px;
          background: #fff;

          .left {
            width: 200px;
            padding: 16px 0px 16px 16px;
            // flex: 50%;
            color: #686f88;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          .rigth {
            padding: 16px 8px 16px 16px;
            flex: 50%;
            color: #090909;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

    .rowNext {
      height: 56px;
      background: #fff;
      display: flex;

      .left {
        width: 200px;
        padding: 16px 8px 16px 16px;
        color: #686f88;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .right {
        padding: 16px 8px 16px 16px;
        flex: 50%;
        color: #090909;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .rightWithImg {
        padding: 8px 8px 16px 16px;
        flex: 50%;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .avatarText {
          div:nth-child(2n) {
            span {
              color: #7145ec;
            }
          }
        }
      }
    }
  }

  .table {
    width: 934px;
  }
}

.del {
  background: #ec3e72 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .title {
    color: #090909;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .addItem {
    // width: 133px;
    height: 40px;
    background: #9197ad;
    border: none;
    color: #fff !important;
    background-color: #9197ad !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &:hover {
      background: #686f88;
      background-color: #686f88 !important;
    }
  }
}

.tablesWrapper {
  width: 934px;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-end;

  .firstRow {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .row {
    color: var(--grey-gray-13-total-black, #090909);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .table1,
  .table2,
  .table3 {
    width: 290px;
  }

  .table1 [class~='ant-table-cell'],
  .table2 [class~='ant-table-cell'],
  .table3 [class~='ant-table-cell'] {
    padding: 8px 6px 8px 16px !important;
  }
}
