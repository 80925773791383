.item {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 36px;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  margin-right: 12px;

  & > img {
    max-width: 100%;
  }
}
