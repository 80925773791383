.root {
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-right: 0;
  width: 216px;
  padding: 8px;
  padding-bottom: 0;
  border-radius: 8px;
  background-color: var(--gray-1);
  overflow: hidden;
  gap: 8px;
}

.menu {
  flex-grow: 1;
}

.menu,
.subMenu,
.footer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: auto;
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.home {
  display: block;
  width: 44px;
  height: 18px;
}

.version {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.versionText {
  font-size: 16px;
  line-height: 20px;
  color: var(--gray-10);
}

.versionTextError {
  color: var(--red-5);
}

.button {
  justify-content: space-between;
}

.link,
.button {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  color: var(--gray-10);
  min-height: 48px;
  border-radius: 4px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: var(--gray-10);
  }

  &:not(.activeLink):hover,
  &:not(.activeSubLink):hover {
    color: var(--gray-10);
    background-color: var(--gray-3);
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.activeLink,
.activeSubLink {
  color: var(--gray-13);
  font-weight: 500;
  background-color: var(--gray-4);

  &:hover {
    color: var(--gray-13);
  }
}

.divider {
  position: relative;
  height: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--gray-4);
  }
}

.subMenu {
  position: absolute;
  top: 0;
  left: calc(100% + 10px);
  z-index: 1;
  background-color: var(--gray-1);
  width: 100%;
  height: 100%;
  transition: left 200ms linear;
}

.subMenuOpen {
  left: 0;
}

.subLink {
  position: relative;
  padding: 4px 8px;
  min-height: 32px;
}

.subMenuIcon {
  opacity: 0;
}

.activeSubLink .subMenuIcon {
  opacity: 1;
}

.avatar {
  margin-left: -8px;
  margin-right: -8px;
  padding: 6px 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray-3);
  }
}

.footer {
  position: relative;
  top: 52px;
  transition: top 200ms linear;
}

.footerShowLogOut {
  top: 0;
}

.tooltip {
  white-space: pre-line;
}
