.item {
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
  grid-template-columns: max-content 1fr;
  align-items: start;
}

.title {
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  color: #838998;
  line-height: 24px;
}

.contentFile {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  align-items: center;
  width: max-content;
  background: #eceef2;
  border-radius: 8px;
  padding: 4px 8px;
}

.buttonDownload {
  cursor: pointer;
  font-size: inherit;
  line-height: 19px;
  letter-spacing: 0.02em;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.5;
  }
}

.buttonDelete {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.typeAo {
  width: max-content;
  padding: 2px 4px;
}

.orangeTypeAo {
  background-color: rgba(245, 123, 81, 0.1);
  color: #f57b51;
}

.blueTypeAo {
  background-color: rgba(30, 167, 198, 0.1);
  color: #1ea7c6;
}

.greenTypeAo {
  background-color: rgba(43, 131, 97, 0.1);
  color: #2b8361;
}

.violetTypeAo {
  background-color: rgba(99, 53, 228, 0.1);
  color: #6335e4;
}

.dash {
  border-bottom: 1px solid #ecf0f5;
}
