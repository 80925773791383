@font-face {
  font-family: Muller;
  src: local('Muller Medium'), local('MullerMedium'),
    url('./assets/fonts/MullerMedium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/MullerMedium.woff2') format('woff2'), url('./assets/fonts/MullerMedium.woff') format('woff'),
    url('./assets/fonts/MullerMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Muller;
  src: local('Muller Regular'), local('MullerRegular'),
    url('./assets/fonts/MullerRegular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/MullerRegular.woff2') format('woff2'), url('./assets/fonts/MullerRegular.woff') format('woff'),
    url('./assets/fonts/MullerRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Muller;
  src: local('Muller Bold'), local('MullerBold'),
    url('./assets/fonts/MullerBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/MullerBold.woff2') format('woff2'), url('./assets/fonts/MullerBold.woff') format('woff'),
    url('./assets/fonts/MullerBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body,
#root {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;

  /* HACK: удалить important как снесу mui */
  font-family: Muller, Arial, sans-serif !important;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background: none;
  border: 0;
}

input {
  border: 0;
}

input:invalid {
  box-shadow: none;
}

a:focus,
button:focus,
textarea:focus,
input:focus {
  outline: none;
}

td.ant-table-column-sort {
  background: none;
}
