.filter {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.company {
  width: 320px;
}

.type,
.status {
  width: 200px;
}

.row {
  cursor: pointer;
}
