.titleWrapper {
  margin-bottom: 38px;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.filter {
  display: flex;
  margin-right: auto;
}

.date {
  height: 40px;
  width: 256px;
  margin-right: 20px;
}

.company {
  width: 320px;
}

.table {
  display: block !important;
  background-color: #FFF;
}

.tableRow {
  display: flex !important;
  padding-right: 10px;

  & > * {
    width: 25%;
  }

  & > *:first-child {
    width: 50%;
  }
}

.tableHead {
  display: block !important;
}

.tableHeaderRow {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);

  & th {
    padding-top: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;
    background-color: #fff !important;
  }
}

.tableBody {
  display: block !important;;
  height: calc(100% - 82px);
  overflow-y: scroll;
  overflow-y: overlay;
}

.tableBody::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.tableBody::-webkit-scrollbar-thumb {
  background: #CED0D7;
  border-radius: 8px;
}

.tableBody::-webkit-scrollbar-track {
  border: 1px solid #ECEEF2;
  background: #fff;
  border-radius: 8px;
}

.tableHeaderBalance {
  color: #838998 !important;
}

.tableBodyRow {
  cursor: pointer;

  &:hover {
    background-color: #FBFBFD;
  }

  & td {
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
  }
}

.tableFooter {
  display: block !important;
}

.tableFooterRow {
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);

  & td {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #29292C;
    border: none;
  }
}

