.signer {
  margin-bottom: 64px !important;
}

.contact {
  margin-bottom: 40px !important;
}

.fullNameWrapper {
  position: relative;
}

.remove[class~='ant-btn'] {
  position: absolute;
  right: -72px;
  top: 50%;
  margin-top: -20px;
}
