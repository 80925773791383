.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  color: #838998;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  transition: all 0.4s;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }

  &:hover .switchMark {
      opacity: 0.7;
  }
}

.switchMark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: #838998;
  box-sizing: border-box;
  &:before {
    content: '';
    position: relative;
    opacity: var(--size-opacity-icon);
    bottom: var(--size-position-bottom-icon);
    height: var(--size-height-icon);
    width: var(--size-width-icon);
    transition: opacity 0.4s;
    border: solid white;
    border-width: var(--size-border-width-icon);
    transform: rotate(45deg);

  }
}
