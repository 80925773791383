.paper {
  display: grid;
  gap: 32px;
  padding: 40px;
  background: #F7F8FA;
  border-radius: 16px;
}

.wrapperRejected {
  width: 600px;
  min-height: 450px;
}

.wrapperSigned {
  width: 600px;
}

.actionWrapper {
  display: grid;
  gap: 40px;
  grid-auto-flow: column;
  justify-content: start;
}

.dropZoneContent {
  width: initial !important;
}

.transactionWrapper {
  width: 600px;
  display: grid;
  gap: 24px;
}

.transactionTitle {
  color: #606176;
  font-size: 24px;
  line-height: 29px;
}

.transactionFields {
  display: grid;
  grid-template-columns: 185px 1fr;
  gap: 24px;
}
