.wrapper {
  position: relative;
  max-width: 894px;
}

.actionTableCell {
  opacity: 0;
  transition: opacity 0.4s;
}

.tableRowStyle {
  background-color: #fff;

  & > td {
    border: none;
  }

  &:hover {
    cursor: pointer;
    background-color: #fbfbfd !important;
  }

  &:hover .actionTableCell {
    opacity: 1;
  }
}

.headRow {
  & th {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 15px;
    vertical-align: bottom;
  }
}
