.header {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: #606176;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 400;
}
