.cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.skeleton {
  border-radius: 4px !important;
}

.totalCell {
  display: flex;
  justify-content: flex-end;
}

.isTotal {
  justify-content: space-between;
  color: #838998;
  font-weight: 700;
}
