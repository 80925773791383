.block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.boldCountry {
  font-weight: 700;
  color: var(--gray-13);
}

.selectCountry [class~='ant-select-selection-item'] .boldCountry {
  font-weight: 400;
}
