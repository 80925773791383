.wrapper {
  position: relative;
  display: grid;
  justify-items: center;
  gap: 15px;
  background: #FFFFFF;
  border-radius: 16px;
  width: 540px;
  padding: 40px 0;
}

.text {
  font-size: 16px;
  line-height: 19px;
  color: #606176;
}

.closeButton {
  position: absolute;
  right: 0;
  padding: 15px;
}
