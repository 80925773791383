.wrapperForm {
  margin-top: 30px;
  width: 600px;
}

.initialForm {
  display: grid;
  gap: 24px;
}

.row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.twoColumn {
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
}

.additionalForm {
  margin-top: 24px;
}

.accordionButton {
  margin-top: 55px;
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  text-align: start;
  font-size: 20px;
  line-height: 24px;
  color: #29292C;
  gap: 8px;
  cursor: pointer;
}

.accordionContent {
  display: grid;
  gap: 40px;
  margin-top: 32px;
}

.actionButtons {
  margin-top: 40px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 24px;
}
