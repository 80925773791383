.root {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-bottom: 20px;
}

.gb {
  position: absolute;
  top: 23px;
  left: 20px;
}
