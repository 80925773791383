.wrapper {
  display: grid;
  gap: 20px;
}

.main {
  max-width: 608px;
  display: grid;
  gap: 24px;
}

.date_container {
  display: grid;
  align-items: start;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}
