.status {
  width: max-content;
  height: 25px;
  box-sizing: border-box;
  color: #ffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 19px;
}
