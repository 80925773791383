.dropdownWrapper {
  margin-bottom: 32px;
}

.dropdownItem:not(:last-child) {
  margin-bottom: 16px;
}

.dropdownStatus {
  width: 100%;
  cursor: pointer;
}
