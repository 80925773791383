.wrapper {
  display: grid;
  gap: 25px;
}

.input {
  height: 40px;
}

.labelFile {
  display: block;
  color: #838998;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 19px;
}
