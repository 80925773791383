.nameBlock {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-content: center;
  gap: 6px;
}

.img {
  width: 22px;
  height: 22px;
}
