.root {
  margin-top: 10px;
  background-color: white;
  border-radius: 8px;

  & th,
  & td {
    border: none !important;
  }
}

.header {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: bold !important;
  color: #5e6c84 !important;
}

.row {
  cursor: pointer;
  background-color: #fff !important;

  &:hover {
    background-color: #fbfbfd !important;
  }
}

.cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.thirdCell {
  align-items: center;
  padding-right: 0 !important;
  padding-top: 5.5px !important;
  padding-bottom: 5.5px !important;
}

.thirdCellAmount {
  display: flex;
  align-items: center;
}

.collapseCell {
  padding: 0 !important;
}

.overdue {
  background-color: #fff1f1 !important;

  &:hover {
    background-color: #ffe9e9 !important;
  }
}

.gracePeriod {
  background-color: #fdffeb !important;

  &:hover {
    background-color: #fbffd2 !important;
  }
}

.fixedCell {
  overflow: hidden;
  margin-right: 10px;
  text-overflow: ellipsis;
  white-space: pre;
}

.total {
  border-top: 1px solid #f2f2f2;
}

.totalCell {
  display: flex !important;
  justify-content: space-between !important;
}

.secondText {
  color: #838998;
}

.bottomBorder {
  border-bottom: 1px solid #f2f2f2;
}

.totalCategory {
  margin-right: 8px;
  color: #838998;
  font-weight: 700;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.icon {
  display: block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border: 4px solid #838998;
  border-left-color: transparent;
  border-right-color: transparent;
}

.openIcon {
  margin-bottom: -4px;
  border-bottom-color: transparent;
}

.closeIcon {
  margin-top: -4px;
  border-top-color: transparent;
}
