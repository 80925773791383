.link {
  display: block;

  &:hover {
    text-decoration: underline;
    color: #091e42;
  }
}

.header {
  & th:first-child {
    min-width: 192px;
  }
}
