.titlePopUp {
  color: #606176;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 40px;
}

.tableWrap {
  border-radius: 8px;
  background: #FFFFFF;
}

.table {
  display: grid;
  grid-template-columns: 188px 1fr 168px;
  align-items: center;
  font-size: 16px;
  & > div:first-child {
    padding-left: 12px;
  }
  & > div:last-child {
    padding-right: 12px;
  }
}
