.bankDetails {
  margin-bottom: 64px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.empty {
  height: 40px;
  margin-bottom: 32px;
}
