.wrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  justify-content: start;
}

.item {
  padding: 11px 8px;
  font-size: 16px;
  color: #707A87;
  cursor: pointer;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.8;
  }

  &Active {
    color: #000000;
    border-bottom: 2px solid #000000;
  }
}
