.wrapper {
  max-width: 894px;
}

.tableRowStyle {
  background-color: #fff;

  & > td {
    border: none;
  }
}

.headRow {
  background-color: #fff;

  & th {
    box-sizing: border-box;
    background-color: inherit;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
    border-bottom: none;
    color: #838998;
  }
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.selectStatus [class~='ant-select-selection-item'] span {
  display: inline !important;
  color: var(--gray-13) !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.status {
  width: 100%;
  text-align: center;
}
