.buttonCustomSettings {
  width: 832px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.buttonTextCustomSwitch {
  padding-left: 0.5em;
  font-size: 20px;
  margin-right: 25px;
  text-transform: none;
  color: #29292C;
}

.iconButtonRoot {
  width: 24px;
  height: 24px;
  padding: 0;

  &:hover {
    background-color: #F7F8FA;
    border-radius: 4px;
  }
}
