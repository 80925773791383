.wrapper {
  background-color: #ffffff;
  border-radius: 8px;
  border-spacing: 0;
}

.headCell {
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.cell.headCell {
  color: #838998;
}

.cell {
  color: #29292c;
  padding: 18px 24px 18px 14px;
}

.tbody:hover tr:not(:last-child) {
  background-color: var(--gray-2);
}

.row {
  cursor: pointer;
}

.footerCell {
  font-weight: bold;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
