.actions {
  display: flex;
  gap: 24px;
}

.yes {
  color: var(--green-6) !important;
}

.no {
  color: var(--red-6) !important;
}

.upload,
.uploadImage {
  margin-bottom: 32px !important;
}

.logo {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.logoImg {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
