.paper {
  display: grid;
  gap: 32px;
  padding: 40px;
  background: #F7F8FA;
  border-radius: 16px;
}

.modalTitle {
  font-size: 24px;
  line-height: 29px;
  color: #606176;
}

.actionButton {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 24px;
}
