.root {
  position: absolute;
  top: 50%;
  left: 50%;

  padding: 40px;

  background-color: #f7f8fa;
  border-radius: 16px;

  transform: translate(-50%, -50%);
}

.title {
  display: block;
  margin-bottom: 24px;

  font-size: 24px;
  line-height: 29px;

  color: #606176;
}

.actions {
  display: flex;
  justify-content: space-between;
}
