.row {
  display: grid;
  gap: 32px;
}

.twoColumnsBlock {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.twoFlexColumnsBlock {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.contentBalance {
  font-weight: 700;
}

.accordionButton {
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  text-align: start;
  font-size: 20px;
  line-height: 24px;
  color: #29292c;
  gap: 8px;
  cursor: pointer;
}

.generalBottom {
  width: max-content;
  height: 40px;

  &:hover .dropZoneGeneralImg {
    filter: invert(37%) sepia(28%) saturate(314%) hue-rotate(199deg) brightness(92%) contrast(83%);
  }
}
