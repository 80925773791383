.wrapper {
  position: relative;
  box-sizing: border-box;
  align-items: center;
  height: 100%;
  padding: 18px;
  display: grid;
  grid-template-rows: 1fr auto;

  background-color: #ffffff;
  border: 1px solid #ecf0f5;
  border-radius: 8px;
  outline: none;
  width: 100%;
  color: #29292c;
  z-index: 1;
  resize: none;

  transition: background-color 0.4s;

  &:hover {
    cursor: text;
    background-color: #fafbfe;
  }

  &:focus-within {
    border: 1px solid #a7afb7;
  }
}

.textarea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  background-color: transparent;
  box-sizing: border-box;
  font-size: 16px;

  &::-webkit-input-placeholder {
    /* Edge */
    color: #838998;
    font-size: 16px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #838998;
    font-size: 16px;
  }

  &::placeholder {
    color: #838998;
    font-size: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ced0d7;
    border-radius: 8px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border: 1px solid #eceef2;
    background: #fff;
    border-radius: 8px;
  }
}

.helperText {
  position: absolute;
  font-size: 12px;
}

.textareaLength {
  margin-top: 10px;
  color: #a7afb7;
  justify-self: end;
}
